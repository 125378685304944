import { Pipe, PipeTransform } from '@angular/core';
import { isValid } from 'date-fns';
import { format, parseDate } from '../../../date.helper';

@Pipe({
  name: 'date',
  pure: true,
  standalone: true,
})
export class DatePipe implements PipeTransform {
  public transform(value: string | Date, formatting: string = 'P'): string {
    if (!value) {
      return '';
    }

    value = parseDate(value);

    if (!isValid(value)) {
      return '';
    }

    return format(value, formatting);
  }

  public parse(value: string): string {
    const date = parseDate(value);

    if (!isValid(date)) {
      return '';
    }

    return format(date, 'yyyy-MM-dd');
  }
}
